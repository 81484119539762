import login from "@/services/login";
import customer from "@/services/customer";
import router from "../router/index";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper.js";

import {
  parseJwt,
  // pendoInit,
  Role,
  CANDIDATE_URL,
  FACILITY_CANDIDATE_URL,
  TFW_CANDIDATE_URL,
  LOGIN_URL,
  getScope,
  getLocalOrgDetail,
  CONSTANT as CONST
} from "@/helpers/helper";
import candidate from "../services/candidate";

const state = {
  isLoading: false,
  userData: JSON.parse(localStorage.getItem("userData")) || "",
  error: "",
  user: [],
  orgRelationship: JSON.parse(localStorage.getItem("orgRelationship")) || "",
  aclData: JSON.parse(localStorage.getItem("aclData")) || "",
  isShowWelcome: false
};

// create getters for all state variables
const getters = {
  isLoginFetching: (state, getters, rootState) => state.isLoading,
  getUserData: (state, getters, rootState) => state.userData || {},
  getUserFullName: (state, getters, rootState) => state.userFullName,
  getUserDetails: (state, getters, rootState) => {
    let { getUserData } = getters;
    if (getUserData && getUserData.access_token) {
      return parseJwt(getUserData.access_token);
    } else {
      return {};
    }
  },
  getSessionDetails: (state, getters, rootState) => {
    let { getUserData } = getters;
    let token = {};
    if (getUserData && getUserData.refresh_token) {
      token.refresh = parseJwt(getUserData.refresh_token);
    }
    if (getUserData && getUserData.access_token) {
      token.access = parseJwt(getUserData.access_token);
    }
    return token;
  },
  getUserId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails.user_id;
  },
  getCustomerId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails.customer_id;
  },
  getCandidateId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails.candidate_id;
  },
  getCandidateEmail(state, getters, rootState) {
    return state.user?.email;
  },
  getRoleScopes(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails && getUserDetails.scopes ? getUserDetails.scopes : [];
  },
  getRole(state, getters, rootState) {
    let { getRoleScopes } = getters;
    return getRoleScopes.length ? getRoleScopes[0] : "";
  },
  getUser: (state, getters, rootState) => state.user,
  getImpersonationStatus(state, getters, rootState) {
    const { getUserDetails } = getters;
    return {
      impersonation: getUserDetails?.impersonating || "",
      customer_name: getUserDetails?.customer_name || "",
      customer_id: getUserDetails?.customer_id || "",
    };
  },
  getCandidateListUrlBasedOnRole(state, getters, rootState) {
    const { customerAdmin, customerRecruiter } = Role;
    const { getRole, getIsParentUser, getOrgID } = getters;
    if (getIsParentUser) {
      // return `${FACILITY_CANDIDATE_URL}?organisation=%5B${getOrgID}%5D`
      return FACILITY_CANDIDATE_URL;
    }
    if ([customerAdmin, customerRecruiter].includes(getRole)) {
      return FACILITY_CANDIDATE_URL;
    }
    return CANDIDATE_URL;
  },
  getJobListUrlBasedOnRole(state, getters) {
    const { getIsParentUser, getOrgID } = getters;
    if (getIsParentUser) {
      return `/job-list?organisation=%5B${getOrgID}%5D`;
    }
    return `/job-list`;
  },
  getOrgID: (state) => state.user?.org_detail?.org_id || null,
  getIsParentUser: (state) => state.user?.org_detail?.is_parent || null,
  getRelationshipOrgDetail: (state) =>
    state.user?.relationship_org_details?.length
      ? state.user?.relationship_org_details
      : null,
  getIsChildUser: (state) => state.user?.org_detail?.is_child || null,
  getIsPartnerHospital: (state) => state.user?.org_detail?.org_type_id == CONST.ORG_TYPES['partner_hospital'],
  getIsJobAuthoriser: (state) =>
    state.user && state.user.users_roles && state.user.users_roles.length
      ? state.user.users_roles.includes("job_authoriser")
      : false,
  getIsCandidateReviewer: (state) =>
    state.user && state.user.users_roles && state.user.users_roles.length
      ? state.user.users_roles.includes("candidate_reviewer")
      : false,
  customerUserRoles: (state) =>
    state.user && state.user.users_roles && state.user.users_roles.length
      ? state.user.users_roles.map((val) => ({
        code: val?.id,
        label: val?.display_name,
        type_name: val?.type_name,
      }))
      : [],
  getOrgName: (state) => state.user?.org_detail?.org_name || null,
  getChildOrgWithCustomerQuery(state, getters, rootState) {
    const { getCustomerId, getIsChildUser, getOrgID: org_id, isHeadlessClient } = getters;
    if (getCustomerId && getIsChildUser && !isHeadlessClient) {
      return `customer_id=${getCustomerId}&organisation_id=${org_id}`;
    } else if (getCustomerId) {
      return `customer_id=${getCustomerId}`;
    }
    return "";
  },
  getChildOrgQuery(state, getters, rootState) {
    const { getIsChildUser, getOrgID: org_id } = getters;
    return getIsChildUser ? `organisation_id=${org_id}` : "";
  },
  isDualUserRole(state, getters, rootState) {
    let { getRoleScopes } = getters;
    return getRoleScopes.length > 1 || false;
  },
  isCustomerUserRoleHave(state, getters, rootState) {
    let { getRoleScopes } = getters;
    return (
      (getRoleScopes.length && getRoleScopes.includes("customer_user")) || false
    );
  },
  isCustomerRecruiterUser(state, getters, rootState) {
    let { getRoleScopes } = getters;
    return (
      (getRoleScopes.length &&
        (getRoleScopes.includes("customer_recruiter") ||
          getRoleScopes.includes("customer_admin")) &&
        getRoleScopes.includes("customer_user")) ||
      false
    );
  },
  isCustomerAdmin(state, getters, rootState) {
    let { getRoleScopes } = getters;
    return (
      (getRoleScopes.length && getRoleScopes.includes("customer_admin")) ||
      false
    );
  },
  isChildOfIndependentOrg(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.is_child &&
      !getUserDetails?.org_detail?.path_acronym;
  },
  has_relationship_with_super_hospital(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_relationships?.some(ele => ele.host_is_super_hospital);
  },
  getUserAcl(state, getters, rootState) {
    return state.aclData || [];
  },
  getUserContactId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.contact_id || null;
  },
  // Get the organization id from the access token
  getOrgIDFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.org_id || getUserDetails?.organisation_id || null;
  },
  // check the user is parent(user) from the access token
  getIsParentUserFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.is_parent || null;
  },
  // check the user is child(user) from the access token
  getIsChildUserFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.is_child || null;
  },
  // check the acronym from the access token
  getPathAcronymFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.path_acronym || null;
  },
  // check the location_id from the access token
  getLocationIDFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.location_id || null;
  },
  // check the org_type from the access token
  getOrgTypeIDFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.org_type_id || null;
  },
  getOrgCountryId(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.country_id || null;
  },
  isUKOrganisation(state, getters) {
    const { getUserDetails } = getters;
    return getUserDetails?.country_id === 182 || false;
  },
  childHasNoAcronym(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.child_has_no_acronym || null;
  },
  hasPartnerChild(state, getters, rootState) {
    let { getUserDetails } = getters;
    return getUserDetails?.org_detail?.has_partner_child || null;
  },
  isUKMainAgency(state, getters, rootState) {
    let { getUserDetails } = getters;
    let child_has_no_acronym = getUserDetails?.org_detail?.child_has_no_acronym || null;
    let has_partner_child = getUserDetails?.org_detail?.has_partner_child || null;
    return child_has_no_acronym && has_partner_child || null;
  },
  isCandidateSupplier(state, getters, rootState) {
    let { getUser } = getters;
    let admin_roles = getUser?.admins_roles || [];
    let recruiter_roles = getUser?.recruiters_roles || [];
    if (admin_roles?.length) {
      return _(admin_roles).some((role) => role.id == 2) || false;
    } else if (recruiter_roles?.length) {
      return _(recruiter_roles).some((role) => role.id == 9) || false;
    }
  },
  isCandidateSupplierFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    let admin_roles = getUserDetails?.admin_roles || [];
    let recruiter_roles = getUserDetails?.recruiter_roles || [];
    if (admin_roles?.length) {
      return _(admin_roles).some((role) => role.id == 2) || false;
    } else if (recruiter_roles?.length) {
      return _(recruiter_roles).some((role) => role.id == 9) || false;
    }
  },
  isJobSupplier(state, getters, rootState) {
    let { getUser } = getters;
    let admin_roles = getUser?.admins_roles || [];
    let recruiter_roles = getUser?.recruiters_roles || [];
    if (admin_roles?.length) {
      return _(admin_roles).some((role) => role.id == 1) || false;
    } else if (recruiter_roles?.length) {
      return _(recruiter_roles).some((role) => role.id == 8) || false;
    }
  },
  isJobSupplierFromAccessToken(state, getters, rootState) {
    let { getUserDetails } = getters;
    let admin_roles = getUserDetails?.admin_roles || [];
    let recruiter_roles = getUserDetails?.recruiter_roles || [];
    if (admin_roles?.length) {
      return _(admin_roles).some((role) => role.id == 1) || false;
    } else if (recruiter_roles?.length) {
      return _(recruiter_roles).some((role) => role.id == 8) || false;
    }
  },
  mainContactInfo(state, getters) {
    const { getUser } = getters;
    return getUser?.main_contact_info || null
  },
  isCandidateRoleFromAccessToken(state, getters) {
    const { getUserDetails } = getters;
    const candidate_roles = ["customer_candidate", "system_candidate"];
    return getUserDetails?.scopes && getUserDetails?.scopes.some(e => candidate_roles.includes(e)) || false;
  },
  getIsRecruiterRole(state, getters) {
    let { getRoleScopes } = getters;
    return (getRoleScopes.length &&
      getRoleScopes.includes("customer_recruiter")) || false;
  },
  getUserName(state, getters) {
    const { getUser, getUserId } = getters;
    let username = getUser.first_name;
    let systemAdmin = 1;
    if (getUserId === systemAdmin) {
      username = this.getUser.user_name;
    }
    if (username) {
      return username.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
    return ''
  },
  isSystemAdminFromAccessToken(state, getters) {
    const { getUserDetails } = getters;
    return getUserDetails?.scopes && getUserDetails?.scopes.some(e => e === "system_admin") || false;
  },
  isIncustomeruserRole(state, getters) {
    const { customerUserRoles, currentUserType } = getters;
    return customerUserRoles.some(v => currentUserType?.type_name ? v?.type_name === currentUserType?.type_name : false) || false;
  },
  isHeadlessClient(state, getters) {
    const { getOrgIDFromAccessToken, getIsParentUserFromAccessToken } = getters;
    return getOrgIDFromAccessToken && !getIsParentUserFromAccessToken
  },
  getChildOrgWithCustomer(state, getters, rootState) {
    const { getCustomerId: customer_uid, getIsChildUser, getOrgID: organisation_id, isHeadlessClient } = getters;
    if (customer_uid && getIsChildUser && !isHeadlessClient) {
      return { customer_uid, organisation_id };
    } else if (customer_uid) {
      return { customer_uid };
    }
    return null;
  },
  getOrgRelationship: (state) => {
    let orgDetail = null;
    if (state.orgRelationship)
      orgDetail = parseJwt(state.orgRelationship);

    return orgDetail;
  },
  getCustomerTypeIDFromAccessToken: (state, getters) => {
    const { getUserDetails } = getters;
    return getUserDetails?.customer_type_id || null;
  },
  getCustomerNameFromAccessToken: (state, getters) => {
    const { getUserDetails } = getters;
    return getUserDetails?.customer_name || null;
  },
  isTFWCustomerFromAccessToken: (state, getters) => {
    const { getUserDetails } = getters;
    return getUserDetails?.customer_type_id === 1 || false;
  },
  isServiceProvider: (state, getters) => {
    const { getUserDetails } = getters;
    return getUserDetails?.customer_type_id === 6 || false
  },
  isShowWelcome: (state) => state.isShowWelcome,
  orgDetailFromAccessToken: (state, getters) => {
    const { getUserDetails } = getters;
    return getUserDetails?.org_detail || null
  },
  isEducationalInstituteOrg: (state, getters) => {
    const {getCustomerTypeIDFromAccessToken} = getters;
    return getCustomerTypeIDFromAccessToken === 5;
  }
};

// actions
const actions = {
  login: async (
    { state, commit, rootState, dispatch, getters },
    { username, password }
  ) => {
    const { customerAdmin, customerRecruiter } = Role;
    localStorage.removeItem("userData");
    commit("LOGIN");
    let payload = new FormData();
    payload.append("username", username);
    payload.append("password", password);

    const {
      params: { org_name: pid },
      name,
    } = router.currentRoute;
    let acronym = pid
    if (['TFW Login', 'TFW Candidate Registration'].includes(name)) acronym = 'tfw'
    let query = acronym ? `?acronym=${acronym}` : "";

    return login
      .loginAccessToken(payload, query)
      .then(async (res) => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Login successful",
        });
        let { data } = res;
        localStorage.setItem("userData", JSON.stringify(data));
        commit("LOGIN_SUCCESS", data);
        let { customer_type_id, scopes, user_id, user_uuid, customer_id, customer_uuid, email, user_roles: customer_user_roles } = parseJwt(
          data.access_token
        );
        const { isServiceProvider } = getters
        const user_role = scopes && scopes.length ? scopes[0] : "";
        // const payload = { user_id, user_role, user_uuid, customer_uuid, email };
        await dispatch("getAcl", { customer_id, path_acronym: acronym })
        // pendoInit(payload);
        commit("RESET_CURRENT_USER_TYPE");
        if (
          user_role === "customer_candidate" ||
          user_role === "system_candidate"
        ) {
          let url = router.currentRoute.query.redirect_url;
          if (url) {
            router.push(url);
          }
          else {
            router.push("/home");
          }
        } else if (user_role === "customer_user") {
          dispatch("setCurrentUserRole", customer_user_roles);
          if (customer_user_roles.length && customer_user_roles[0]?.id === 6)
            router.push("/management-report")
          else
            router.push("/list-activity");
        } else {
          router.push(
            isServiceProvider ? "spc-dashboard" :
              [customerAdmin, customerRecruiter].includes(user_role)
                ? "/recruiter-dashboard"
                : "/dashboard"
          );
        }
        await dispatch("fetchUser");
        await dispatch("fetchAlert");
        commit("SET_SHOW_ALERT", true);
        //setting welcome model
        if (isServiceProvider) {
          commit("SET_WELCOME_MODEL", true)
        }
        return res;
      })
      .catch((err) => {
        if ([400, 401].includes(err?.response?.status)) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: err.response.data.detail,
          });
        }
        console.error("error in login", err);
        commit("LOGINERROR", err);
        return err;
      });
  },

  forgot: async (
    { state, commit, rootState, dispatch, getters },
    { email }
  ) => {
    let path_acronym = getLocalOrgDetail().path_acronym
    return login
      .forgotPassword(email, path_acronym)
      .then(() => { })
      .catch((err) => {
        console.error("error in forgot", err);
        return err;
      })
      .finally(() => {
        dispatch("showToast", {
          class: "bg-success text-white",
          message:
            "if you are registered in the system, you will get an email to reset your password.",
        });
        router.push(LOGIN_URL());
      });
  },
  reset: async ({ state, commit, rootState, dispatch, getters }, payload) => {
    const { confirmPassword, ...rest } = payload;
    let path_acronym = getLocalOrgDetail().path_acronym
    if (confirmPassword != rest.new_password) {
      dispatch("showToast", { message: "Passwords does not match!" });
      return;
    } else {
      dispatch("showToast", { message: "Resetting password" });
      return login
        .resetPassword(rest)
        .then(async (res) => {
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Password Reset Successfully!",
          });
          if (path_acronym == 'tfw') {
            router.push(`/tfw/login`)
          } else {
            router.push(LOGIN_URL());
          }
        })
        .catch((err) => {
          console.error("error in reset", err);
          return err;
        });
    }
  },
  unsubscribe: async (
    { state, commit, rootState, dispatch, getters },
    { email }
  ) => {
    return login
      .unsubscribe(email)
      .then(() => { })
      .catch((err) => {
        console.error("error in unsubscribe", err);
        return err;
      })
      .finally(() => {
        dispatch("showToast", {
          message: "You are unsubscribed from the system",
        });
        router.push(LOGIN_URL());
      });
  },
  logout({ state, dispatch, commit, getters }) {
    localStorage.removeItem("userData");
    localStorage.removeItem("orgRelationship");
    localStorage.removeItem("aclData");
    commit("LOGOUT");
    dispatch("resetCandidate");
    dispatch("resetJobList");
    dispatch("resetCandidateList");
    dispatch("resetEmployeeList");
    dispatch("resetApplicationStatus");
    dispatch("resetAlertModal");
    dispatch("closeConnection");
    commit("SET_SHOW_ALERT", false);
    setTimeout(() => {
      router.push(LOGIN_URL());
    }, 0);
  },
  redirectToReset({ state, commit }, token) {
    router.push(`/auth/reset-password?token=${token}`);
  },
  refreshToken: async ({ state, commit, dispatch }) => {
    let userData = JSON.parse(localStorage.getItem("userData"));
    const { refresh_token } = userData;
    return login
      .refreshToken(refresh_token)
      .then(async (res) => {
        let { data } = res;
        localStorage.removeItem("userData");
        localStorage.setItem("userData", JSON.stringify(data));
        commit("LOGIN_SUCCESS", data);
        return res;
      })
      .catch((err) => {
        console.log("error in refreshing token");
        commit("LOGINERROR", err);
        dispatch("logout")
        throw err;
      })
      .finally(() => {
        dispatch("hideLoader");
      });
  },
  startImpersonation({ state, commit, dispatch, getters }, payload) {
    return customer
      .startImpersonation(payload)
      .then(async (res) => {
        if (res.status == 200) {
          let { data } = res;
          localStorage.removeItem("userData");
          localStorage.setItem("userData", JSON.stringify(data));
          commit("IMPERSONATION_SUCCESS", data);
          dispatch("fetchUser");
          dispatch("getTotalCandidates");
          dispatch("clearAllSelectedFilters");
          let { customer_id } = parseJwt(
            data.access_token
          );
          await dispatch("getAcl", { customer_id })
          router.push({
            path: FACILITY_CANDIDATE_URL,
          });
          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Role Switched successfully",
          });
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while Switching Role",
          });
        }
        console.log("error in Switch Role", err);
        return err;
      });
  },
  stopImpersonation({ state, commit, dispatch, getters }) {
    let query = {
      active: "[true,null]",
      deleted: "[false]",
    };
    return customer
      .stopImpersonation()
      .then(async (res) => {
        if (res.status == 200) {
          let { data } = res;
          localStorage.removeItem("userData");
          localStorage.setItem("userData", JSON.stringify(data));
          commit("IMPERSONATION_SUCCESS", data);
          dispatch("fetchUser");
          dispatch("getTotalCandidates");
          dispatch("clearAllSelectedFilters");
          let { customer_id } = parseJwt(
            data.access_token
          );
          await dispatch("getAcl", { customer_id })
          router
            .push({
              path: CANDIDATE_URL,
            })
            .then(() => {
              dispatch("updateSelectedFilterFromFilterCode", query);
            });

          dispatch("showToast", {
            class: "bg-success text-white",
            message: "Role Switched successfully",
          });
        }
      })
      .catch((err) => {
        if (err.response.status == 500) {
          dispatch("showToast", {
            class: "bg-danger text-white",
            message: "Error while Switching Role",
          });
        }
        console.log("error in Switch Role", err);
        return err;
      });
  },
  fetchUser({ state, commit, rootState, dispatch, getters }) {
    let { getPathAcronymFromAccessToken } = getters;
    commit("RESET_CURRENT_USER_TYPE");
    let query = ""
    if (getPathAcronymFromAccessToken) query = `path_acronym=${getPathAcronymFromAccessToken}`
    return customer
      .getUser(query)
      .then((res) => {
        let { data } = res;
        const { getUser, getUserId, getRoleScopes } = getters;
        const { full_name } = getUser;
        commit("SET_USER", data);
        commit("SET_USER_FULL_NAME", full_name);
        let rolecheck = getRoleScopes.length ? getRoleScopes[0] : "";
        if (
          rolecheck === "customer_candidate" ||
          rolecheck === "system_candidate"
        ) {
          return;
        } else if (
          [
            Role.customerAdmin,
            Role.customerRecruiter,
            Role.systemAdmin,
            Role.systemRecruiter,
          ].includes(rolecheck)
        ) {
          if (
            (rolecheck === "customer_recruiter" ||
              rolecheck === "customer_admin") && !getRoleScopes.includes("customer_user")
          ) {
            dispatch("fetchUserUploadedDocument", getUser.id);
          }
        }
        if (getRoleScopes.includes("customer_user")) {
          const { users_roles } = data;
          dispatch("fetchUserUploadedDocument", getUser.id);
          return dispatch("setCurrentUserRole", users_roles);
        }

        return res;
      })
      .catch((err) => {
        console.error("error fetching user", err);
        return err;
      });
  },
  updateUser: ({ getters, dispatch }, payload) => {
    return customer
      .updateUser(payload)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        dispatch("showToast", {
          class: "bg-danger text-white",
          message: "Error while updating user",
        });
        console.error("error fetching user", err);
      })
  },
  fetchAlert: ({ getters, dispatch }) => {
    const { getUserId, client, getRoleScopes } = getters;
    let rolecheck = getScope();
    if (
      [
        Role.customerAdmin,
        Role.customerRecruiter,
        Role.systemAdmin,
        Role.systemRecruiter,
      ].includes(rolecheck)
    ) {
      dispatch("fetchNotificationAlerts");
      dispatch("fetchOnTimeNotificationAlerts");
    }
  },
  setCurrentUserRole({ commit, dispatch, getters }, users_roles) {
    const { getRoleScopes } = getters;
    let currentUserType =
      users_roles && users_roles.length ? users_roles[0] : null;
    const t = {
      customer_recruiter: {
        code: "customer_recruiter",
        label: "Customer Recruiter",
      },
      customer_admin: { code: "customer_admin", label: "Customer Admin" },
    };
    let rolecheck = getRoleScopes.length ? getRoleScopes[0] : "";
    currentUserType = t[rolecheck]
      ? t[rolecheck]
      : {
        ...currentUserType,
        label: currentUserType?.display_name,
        code: currentUserType?.id,
      };
    commit("SET_IS_SHOW_USER_TYPE_MODAL", true);
    commit("SET_ACTIVE_TAB", 0);
    commit("SET_CURRENT_USER_TYPE", currentUserType);
    return;
  },
  checkOrgRelationship: ({ commit, dispatch, getters }, payload) => {
    return login
      .checkOrgRelationship(payload)
      .then(res => {
        const { data } = res;
        dispatch("showToast", {
          class: "bg-success text-white",
          message: "Verification code sent to user email!"
        })
        return res;
      })
      .catch(err => {
        let message = "Error while Check Organisation Relationship";
        if ([409].includes(err?.response?.status)) {
          message = err.response.data.detail?.message;
        }
        else if ([400, 404].includes(err?.response?.status)) {
          message = err.response.data.detail;
        }
        dispatch("showToast", {
          class: "bg-danger text-white",
          message
        })
        console.log(message, err);
        return err;
      })
  },
  createCustomerOrgRelationship: ({ commit, dispatch, getters }, data = {}) => {
    const { payload, access_token } = data;
    return customer
      .createCustomerOrgRelationship({ payload, access_token })
      .then(res => {
        const { data } = res;
        localStorage.removeItem("orgRelationship");
        return res;
      })
      .catch(err => {
        const message = "Error while Organisation Registration";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        })
        return err;
      })
  },
  checkEmailOTP: ({ commit, dispatch, getters }, payload = {}) => {
    const { username, password } = payload;
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    return login
      .checkEmailOTP(formData)
      .then(res => {
        const { data } = res;
        localStorage.removeItem("orgRelationship");
        localStorage.setItem("orgRelationship", JSON.stringify(data));
        return res;
      })
      .catch(err => {
        let message = "Error while verify password";
        dispatch("showToast", {
          class: "bg-danger text-white",
          message,
        })
        return err;
      })
  },
  getAcl: ({ commit, dispatch, getters }, payload = {}) => {
    let queryPayload = {};
    const { customer_id, path_acronym } = payload;
    if (customer_id) {
      queryPayload = { ...queryPayload, customer_id }
    }
    if (path_acronym) {
      queryPayload = { ...queryPayload, path_acronym }
    }
    const query = getFilterQueryStringWithoutArray(queryPayload);
    localStorage.removeItem("aclData");
    return login
      .getAcl({ query })
      .then(res => {
        let { data } = res;
        localStorage.setItem("aclData", JSON.stringify(data));
        commit("SET_ACL_DATA", data)
        return res;
      })
      .catch(err => {
        console.log("Error while Fetching ACL Data", err)
        return err
      })
  },
  switchCandidateOrg: ({ getters, commit, dispatch }, acronym) => {
    const queryString = getFilterQueryStringWithoutArray({ acronym });
    return candidate.candidateLoginAccessToken(queryString)
      .then(async res => {
        const { data } = res;
        localStorage.setItem("userData", JSON.stringify(data));
        commit("LOGIN_SUCCESS", data);
        await dispatch("fetchUser");
        const { getCustomerId: customer } = getters;
        await dispatch("getUserMe", { customer });
        return res;
      })
      .catch(err => {
        console.log("Error while Fetching Login Access Token", err)
        return err
      })
  }
};

// mutations
const mutations = {
  ["LOGINERROR"](state, error) {
    state.error = error;
    state.isLoading = false;
    state.userData = "";
  },
  ["LOGIN_SUCCESS"](state, payload) {
    state.error = false;
    state.isLoading = false;
    state.userData = payload;
  },
  ["LOGIN"](state, payload) {
    state.error = false;
    state.isLoading = true;
    state.userData = "";
  },
  ["LOGOUT"](state, payload) {
    state.error = false;
    state.isLoading = false;
    state.userData = "";
    state.user = [];
  },
  ["IMPERSONATION_SUCCESS"](state, payload) {
    state.userData = payload;
  },
  ["SET_USER"](state, payload) {
    state.user = payload;
  },
  ["SET_USER_FULL_NAME"](state, payload) {
    state.userFullName = payload;
  },
  ["SET_ACL_DATA"](state, payload) {
    state.aclData = payload;
  },
  ["SET_WELCOME_MODEL"](state, payload) {
    state.isShowWelcome = payload
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
